import { Grid, Popup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import YearSelector from './components/YearSelector/YearSelector';
import QuestionScaleInput from './components/QuestionScaleInput/QuestionScaleInput';
import CustomCheckbox from '../../shared/CustomCheckbox/CustomCheckbox';
import { api } from '../../utils/api';

import './History.css';
import CustomRadio from '../../shared/CustomRadio/CustomRadio';

/**
 * @returns {JSX.Element|string}
 * По типам вопросов - см utils/Survey/SurveyAnswerTypeEnum
 */
export default function History() {
  let { id } = useParams();
  let navigate = useNavigate();
  let [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  let [years] = useState([
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 3,
    new Date().getFullYear() - 4,
    new Date().getFullYear() - 5,
    new Date().getFullYear() - 6,
    new Date().getFullYear() - 7,
    new Date().getFullYear() - 8,
    new Date().getFullYear() - 9,
    new Date().getFullYear() - 10,
  ]);

  let [questions, setQuestion] = useState([]);
  let [historyItems, setHistoryItems] = useState([]);
  let [selectedHistoryItemId, setSelectedHistoryItemId] = useState(id === undefined ? null : parseInt(id));
  let [cars, setCars] = useState([]);
  let [activeCarId, setActiveCarId] = useState(null);
  let [repairItems, setRepairItems] = useState([]);
  let [isLoading, setIsLoading] = useState(false);

  let load = (year) => {
    setIsLoading(true);

    api()
      .getServiceHistory(year ?? currentYear)
      .then((items) => {
        setHistoryItems(
          items.map((item) => ({
            id: parseInt(item.WorkOrderId),
            CarId: item.CarId,
            isOwner: item.IsOwner,
            ownerDescr: item.OwnerDescr,
            orderNumber: item.WorkorderNumber,
            cost: item.TotalSum,
            time: item.DateClose,
            dealer: item.DealerDescr,
            dealerPointId: item.DealerPointId,
            carNumber: item.LicencePlate,
            vin: item.Vin,
            odometer: item.Odometer,
            reason: item.Reason,
            createAt: item.DateAccess,
            qualityAssessmentComplete: (() => {
              if (item.CsiStatusId === 0 && item.CsiSurvayDate === null) {
                return null;
              }
              if (item.CsiStatusId === 1) {
                return false;
              }
              if (item.CsiSurvayDate !== null) {
                return true;
              }
              return null;
            })(),
          })),
        );

        let uniqueCars = [];

        items.forEach((element) => {
          if (!uniqueCars.find((car) => car.CarId === element.CarId)) {
            uniqueCars.push({ CarId: element.CarId, CarDescr: element.CarDescr });
          }
        });

        setCars(uniqueCars);
        if (uniqueCars[0]) {
          setActiveCarId(uniqueCars[0].CarId);
        }
        setIsLoading(false);
      });
  };

  useEffect(load, []);

  useEffect(() => {
    if (id !== undefined && historyItems.length > 0) {
      selectHistoryItem(parseInt(id));
    }
  }, [id, historyItems]);

  let onSetCurrentYear = (val) => {
    setCurrentYear(val);
    load(val);
  };

  const _mapQuestionsArray = (resp) => {
    let result = [];

    const getVariants = (question) =>
      resp.PossibleAnswerModelList.filter((possibleAnswer) => possibleAnswer.QuestionId === question.QuestionId).map(
        (possibleAnswer) => ({
          id: possibleAnswer.AnswerId,
          title: possibleAnswer.Descr,
          isChecked: possibleAnswer.IsChecked,
          openTextFieldIfChecked: possibleAnswer.IsTextAnswer,
          text: possibleAnswer.TextValue ?? undefined,
        }),
      );

    resp.PrimaryQuestionModelList.forEach((primaryQuestion) => {
      result.push({
        id: primaryQuestion.QuestionId,
        title: primaryQuestion.Descr,
        type: primaryQuestion.AnswerTypeId,
        answer: primaryQuestion.HappyIndex ?? null,
        variants: getVariants(primaryQuestion),
      });

      resp.SecondaryQuestionModelList.filter(
        (secondaryQuestion) => secondaryQuestion.MasterQuestionId === primaryQuestion.QuestionId,
      ).forEach((question) => {
        result.push({
          id: question.QuestionId,
          parentId: question.MasterQuestionId,
          viewIfParentIn: [question.HappyIndexMin, question.HappyIndexMax],
          title: question.Descr,
          type: 3, // SurveyAnswerTypeEnum.SECONDARY_MULTIPLE_CHOICE
          masterQuestionAnswerId: question.MasterQuestionAnswerId,
          answer: null,
          variants: getVariants(question),
        });
      });
    });

    setQuestion(result);
  };

  let selectHistoryItem = (id) => {
    api()
      .getServiceHistoryInfo(id)
      .then((resp) => {
        setSelectedHistoryItemId(id);
        setRepairItems(
          resp.Rows.map((row) => ({
            cost: row.TotalSum,
            qnt: row.Qnt,
            name: row.Descr,
            type: row.TypeId,
          })),
        );

        api()
          .getAnswers(id)
          .then((resp) => {
            _mapQuestionsArray(resp);
          });
      });
  };

  let renderHistoryItem = (historyItem, index, selectable) => (
    <div
      key={index}
      className={'history-item' + (!selectable ? ' is-selected' : '')}
      onClick={() => selectHistoryItem(historyItem.id)}
    >
      <div className={'cost'}>
        <img src={'/images/price.svg'} />
        {historyItem.cost == null ? (
          '-'
        ) : (
          <>
            {historyItem.cost.toLocaleString('ru-RU')}{' '}
            <img
              style={{
                width: '18px',
                verticalAlign: 'sub',
                marginRight: '0px',
              }}
              src={'/images/rub.svg'}
            />
          </>
        )}
      </div>

      <Grid className={'order-properties'}>
        <Grid.Row>
          <Grid.Column width={5}>Дата:</Grid.Column>
          <Grid.Column width={8}>{historyItem.time}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Дилер:</Grid.Column>
          <Popup
            trigger={
              <Grid.Column width={8} className={'dealer-name'}>
                {historyItem.dealer}
              </Grid.Column>
            }
            content={historyItem.dealer}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Заказ-наряд:</Grid.Column>
          <Grid.Column width={8}>{historyItem.orderNumber}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Гос номер:</Grid.Column>
          <Grid.Column width={8}>{historyItem.carNumber}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>VIN:</Grid.Column>
          <Grid.Column width={8}>{historyItem.vin}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>Пробег:</Grid.Column>
          <Grid.Column width={8}>{historyItem.odometer.toLocaleString('ru-RU')}</Grid.Column>
        </Grid.Row>
        {!historyItem.isOwner && (
          <Grid.Row>
            <Grid.Column width={5}>Владелец:</Grid.Column>
            <Grid.Column width={8}>{historyItem.ownerDescr}</Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      {historyItem.qualityAssessmentComplete == null ? (
        ''
      ) : (
        <div className={'quality-assessment ' + (historyItem.qualityAssessmentComplete ? 'complete' : 'not-complete')}>
          <img src={'/images/' + (historyItem.qualityAssessmentComplete ? 'green-check' : 'red-bell') + '.svg'} />{' '}
          {historyItem.qualityAssessmentComplete ? 'Оценка качества выполнена' : 'Выполните оценку качества'}
        </div>
      )}

      {selectable ? (
        <div className={'more-link'}>
          Подробнее <img className={'on-hover'} src={'/images/arrow-right.svg'} />
          <img className={'regular'} src={'/images/arrow-right-not-active.svg'} />
        </div>
      ) : (
        ''
      )}
    </div>
  );

  let getPreviousAnswer = (questions, index) => {
    if (questions[index - 1].parentId !== undefined) {
      const previousQuestionParentQuestion = questions.find((q) => q.id === questions[index - 1].parentId);

      if (previousQuestionParentQuestion) {
        if (previousQuestionParentQuestion.type === 2) {
          const toggleAnswerIsChecked = !!previousQuestionParentQuestion.variants.find((ans) => ans.isChecked);

          // Тупорылый костыль, т к false в анкете считается валидным значением.
          // Нужен null для того, чтобы показать что ответ не выбран
          return toggleAnswerIsChecked || null;
        } else {
          return previousQuestionParentQuestion.answer || null;
        }
      } else {
        return null;
      }
    }

    // Если пред вопрос - первичный
    if (questions[index - 1].type === 2) {
      const toggleAnswerIsChecked = !!questions[index - 1].variants.find((ans) => ans.isChecked);

      // Тупорылый костыль, т к false в анкете считается валидным значением.
      // Нужен null для того, чтобы показать что ответ не выбран
      return toggleAnswerIsChecked || null;
    } else {
      return questions[index - 1].answer || null;
    }
  };

  let getRadioCheckedValue = (q) => {
    let result = -1;

    q.variants.forEach((variant, index) => {
      if (variant.isChecked) {
        result = index + 1;
      }
    });

    return result;
  };

  let getParentQuestion = (question) => {
    return questions.find((x) => x.id === question.parentId);
  };

  // Renders

  // Отображаем карточку ремонта

  if (selectedHistoryItemId !== null && historyItems.length > 0) {
    let sendAnswersToServer = () => {
      let happyIndexData = '';

      questions
        .filter((x) => x.parentId === undefined && x.type !== 3)
        .forEach((x) => {
          happyIndexData +=
            x.id + ',' + (x.type === 6 ? x.answer : '') + (x.type === 2 ? getRadioCheckedValue(x) : '') + '|';
        });

      let primaryAnswersData = '';

      questions
        .filter((x) => x.parentId === undefined)
        .forEach((x) => {
          if (x.variants.length > 0) {
            x.variants.forEach((variant) => {
              primaryAnswersData +=
                variant.id + ',' + (variant.isChecked ? '1' : '0') + ',' + (variant.text ?? '') + '|';
            });
          }
        });

      let answersData = '';

      questions
        .filter((x) => x.parentId !== undefined)
        .forEach((x) => {
          if (x.variants.length > 0) {
            x.variants.forEach((variant) => {
              answersData += variant.id + ',' + (variant.isChecked ? '1' : '0') + ',' + (variant.text ?? '') + '|';
            });
          }
        });

      api()
        .setAnswers(selectedHistoryItemId, happyIndexData, answersData)
        .then((resp) => {
          if (resp.ErrorInfo === '' || resp.ErrorInfo === null) {
            toast.success(resp.UserInfo === null || resp.UserInfo === '' ? 'Анкета сохранена' : resp.UserInfo);
            load();

            selectHistoryItem(selectedHistoryItemId);
          } else {
            toast.error(resp.ErrorInfo);
          }
        });
    };

    let getQualityAssessmentBlock = (isComplete = false) => {
      return (
        <div className={'quality-assessment-container'}>
          <div className={'title'}>Оценка качества</div>

          {window.config.shortName === 'Exeed.ru' && (
            <button
              className={'rate-master-btn'}
              onClick={() => {
                let windowReference = window.open('', '_blank');
                let item = historyItems.filter((x) => x.id === selectedHistoryItemId)[0];

                api()
                  .getUrlForRateMaster(item.dealerPointId, item.orderNumber)
                  .then((resp) => {
                    if (resp.isOk) {
                      windowReference.location = resp.url;
                    } else {
                      toast.error('Возникла ошибка при открытии страницы');
                    }
                  });
              }}
            >
              Оценить работу мастера приёмщика
            </button>
          )}

          {!isComplete && (
            <div className={'scale'}>
              <div className={'not-satisfied-label'}>Неудовлетворительно</div>
              <div className={'scale-item scale-1'}>1</div>
              <div className={'scale-item scale-2'}>2</div>
              <div className={'scale-item scale-3'}>3</div>
              <div className={'scale-item scale-4'}>4</div>
              <div className={'scale-item scale-5'}>5</div>
              <div className={'satisfied-label'}>Нейтрально</div>
              <div className={'scale-item scale-6'}>6</div>
              <div className={'scale-item scale-7'}>7</div>
              <div className={'scale-item scale-8'}>8</div>
              <div className={'scale-item scale-9'}>9</div>
              <div className={'scale-item scale-10'}>10</div>
              <div className={'total-satisfied-label'}>Отлично</div>
            </div>
          )}

          {questions.map((question, index) => {
            const isFirstPrimaryQuestion = index === 0;
            const showNextQuestion = (index > 0 ? getPreviousAnswer(questions, index) : null) !== null;
            const isPrimaryQuestion = question.parentId === undefined;

            // Показать доп. вопрос в зависимости от выбранного значения основного вопроса
            const showOptionalSecondaryQuestion = () => {
              const parentQuestion = getParentQuestion(question);

              switch (parentQuestion?.type) {
                // Checkbox
                case 2:
                  return parentQuestion?.variants?.find((v) => v.isChecked)?.id === question.masterQuestionAnswerId;

                // 10 бальная шкала
                case 6: {
                  const parentAnswerValue = parentQuestion?.answer || null;

                  return question.viewIfParentIn?.length === 2
                    ? parentAnswerValue >= question.viewIfParentIn[0] && parentAnswerValue <= question.viewIfParentIn[1]
                    : false;
                }

                default:
                  return false;
              }
            };

            if (
              isComplete ||
              isFirstPrimaryQuestion ||
              (isPrimaryQuestion && showNextQuestion) ||
              showOptionalSecondaryQuestion()
            ) {
              return (
                <div key={index}>
                  <div className={'question-title'}>
                    {process.env.NODE_ENV === 'development' && `${question.id} `}
                    {question.title} {question.parent}
                  </div>

                  {/* Checkbox */}
                  {question.type === 3 && (
                    <div className={'not-satisfied-variant'}>
                      {question.variants
                        .filter((x) => !(isComplete && !x.isChecked))
                        .map((variant, variantIndex) => (
                          <div key={variantIndex}>
                            <CustomCheckbox
                              isComplete={isComplete}
                              value={variant.isChecked}
                              label={variant.title}
                              onChange={(val) => {
                                if (isComplete) {
                                  return;
                                }
                                let temp = questions.map((x) => x);
                                temp[index].variants[variantIndex].isChecked = val;
                                setQuestion(temp);
                              }}
                            />

                            {variant.openTextFieldIfChecked && variant.isChecked && (
                              <input
                                placeholder={'Введите...'}
                                className={'other-text-field-variant'}
                                disabled={isComplete}
                                value={variant.text}
                                onChange={(e) => {
                                  if (isComplete) {
                                    return;
                                  }

                                  let temp = questions.map((x) => {
                                    if (x.id === question.id) {
                                      x.variants = question.variants.map((varik) => {
                                        if (varik.id === variant.id) {
                                          varik.text = e.target.value;
                                        }
                                        return varik;
                                      });
                                      return x;
                                    }
                                    return x;
                                  });

                                  setQuestion(temp);
                                }}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  )}

                  {/* Выбор значения из 10 бальной шкалы */}
                  {question.type === 6 && (
                    <QuestionScaleInput
                      isComplete={isComplete}
                      value={question.answer}
                      onChange={(answer) => {
                        if (isComplete) {
                          return;
                        }

                        let temp = questions.map((x) => x);
                        temp[index].answer = answer;
                        setQuestion(temp);
                      }}
                    />
                  )}

                  {/* Radio */}
                  {question.type === 2 &&
                    (isComplete ? (
                      <div className={'not-satisfied-variant'}>
                        {question.variants
                          .filter((x) => x.id.match(/(\d+)\^(\d+)\^(\d+)$/)[3] === String(question.answer))
                          .map((variant, variantIndex) => (
                            <div key={variantIndex}>
                              <CustomRadio isComplete={isComplete} value={true} label={variant.title} />
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className={'not-satisfied-variant'}>
                        {question.variants
                          .filter((x) => !(isComplete && !x.isChecked))
                          .map((variant, variantIndex) => (
                            <div key={variantIndex}>
                              <CustomRadio
                                isComplete={isComplete}
                                value={variant.isChecked}
                                label={variant.title}
                                onChange={(val) => {
                                  if (isComplete) {
                                    return;
                                  }

                                  let temp = questions.map((x) => x);
                                  temp[index].variants.forEach((x) => (x.isChecked = false));
                                  temp[index].variants[variantIndex].isChecked = val;
                                  setQuestion(temp);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              );
            }
          })}

          {questions.filter((x) => x.parentId === undefined && x.type === 6).every((x) => x.answer !== null) &&
            questions
              .filter((x) => x.parentId === undefined && (x.type === 3 || x.type === 2))
              .every((x) => x.variants.some((z) => z.isChecked)) &&
            !isComplete && (
              <button onClick={sendAnswersToServer} className={'finish-btn'}>
                Завершить опрос
              </button>
            )}
        </div>
      );
    };

    if (historyItems.filter((x) => x.id === selectedHistoryItemId)[0] === undefined) {
      return '';
    }

    return (
      <div>
        <div className={'history-page'}>
          <Grid className={'header'}>
            <Grid.Row>
              <Grid.Column
                width={16}
                onClick={() => {
                  setSelectedHistoryItemId(null);
                  navigate('/history');
                }}
              >
                <h1>
                  <img src={'/images/arrow-back.svg'} />
                  Заказ-наряд {historyItems.filter((x) => x.id === selectedHistoryItemId)[0].orderNumber} от{' '}
                  {historyItems.filter((x) => x.id === selectedHistoryItemId)[0].createAt}
                </h1>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div>
            {historyItems.filter((x) => x.id === selectedHistoryItemId)[0].qualityAssessmentComplete === false &&
              getQualityAssessmentBlock()}

            <div className={'history-items-and-order-details'}>
              <div className={'history-items'}>
                {renderHistoryItem(
                  historyItems.filter((x) => x.id === selectedHistoryItemId)[0],
                  selectedHistoryItemId,
                  false,
                )}
              </div>

              <div className={'order-details'}>
                <div className={'reason-title'}>Причина обращения:</div>

                <div className={'reasons'}>
                  <div className={'reason-item'}>
                    {historyItems.filter((x) => x.id === selectedHistoryItemId)[0].reason}
                  </div>
                </div>

                {!!repairItems.filter((x) => x.type === 1).length && (
                  <table className={'items-table'}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Работы</th>
                        <th>НЧ</th>
                        <th>Стоимость</th>
                      </tr>
                    </thead>

                    <tbody>
                      {repairItems
                        .filter((x) => x.type === 1)
                        .map((x, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{x.name}</td>
                            <td>{x.qnt}</td>
                            <td>{x.cost.toLocaleString('ru-RU')}</td>
                          </tr>
                        ))}
                    </tbody>

                    <tfoot>
                      <tr>
                        <th colSpan={3}>Итоговая стоимость</th>
                        <td>
                          {repairItems
                            .filter((x) => x.type === 1)
                            .map((x) => x.cost)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toLocaleString('ru-RU')}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!!repairItems.filter((x) => x.type === 2).length && (
                  <table className={'items-table'}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Детали</th>
                        <th>НЧ</th>
                        <th>Стоимость</th>
                      </tr>
                    </thead>

                    <tbody>
                      {repairItems
                        .filter((x) => x.type === 2)
                        .map((x, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{x.name}</td>
                            <td>{x.qnt}</td>
                            <td>{x.cost.toLocaleString('ru-RU')}</td>
                          </tr>
                        ))}
                    </tbody>

                    <tfoot>
                      <tr>
                        <th colSpan={3}>Итоговая стоимость</th>
                        <td>
                          {repairItems
                            .filter((x) => x.type === 2)
                            .map((x) => x.cost)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toLocaleString('ru-RU')}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>

            {historyItems.filter((x) => x.id === selectedHistoryItemId)[0].qualityAssessmentComplete &&
              getQualityAssessmentBlock(true)}
          </div>
        </div>
      </div>
    );
  }

  // Отображаем список ремонтов
  return (
    <div>
      <div className={'history-page'}>
        <Grid className={'header'}>
          <Grid.Row>
            <Grid.Column width={8}>
              <h1>История ремонтов</h1>
            </Grid.Column>

            <Grid.Column width={8}>
              <div className={'year-selector-container'}>
                <YearSelector value={currentYear} years={years} onSelect={onSetCurrentYear} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {isLoading && <div className="loader">Loading...</div>}

        {!isLoading && !cars.length && (
          <div className={'not-found-message'}>История ремонтов для выбранного года отсутствует</div>
        )}

        {!isLoading && (
          <div className={'car-selector'}>
            {cars.map((car) => (
              <div
                key={car.CarId}
                className={
                  'car-item' +
                  (car.CarId === activeCarId ? ' selected' : '') +
                  (car.CarDescr.length > 30 ? ' is-long-name' : '')
                }
                onClick={() => setActiveCarId(car.CarId)}
              >
                {car.CarDescr}
              </div>
            ))}
          </div>
        )}

        {!isLoading && (
          <div className={'history-items'}>
            {historyItems
              .filter((item) => item.CarId === activeCarId)
              .map((historyItem, index) => renderHistoryItem(historyItem, index, true))}
          </div>
        )}
      </div>
    </div>
  );
}
