import './Cars.css';
import {useNavigate} from "react-router-dom";
import {Grid, Modal, Popup} from "semantic-ui-react";
import MarkElement from "./components/MarkElement";
import {useEffect, useState} from "react";
import {api} from "../../utils/api";
import CustomInput from "../../shared/CustomInput/CustomInput";
import { toast } from 'react-toastify';
import SsiContainer from "../../shared/SsiContainer/SsiContainer";
import CustomTextarea from "../../shared/CustomTextarea/CustomTextarea";

export default function Cars({onChangePage}) {
    let [currentCarSelected, setCurrentCarSelected] = useState(null);
    let navigate = useNavigate();

    let [cars, setCars] = useState([]);

    let [vinToDelete, setVinToDelete] = useState('');
    let [currentCarVin, setCurrentCarVin] = useState('');
    let [checkVinToDeleteError, setCheckVinToDeleteError] = useState(false);

    let [isLoading, setIsLoading] = useState(false);
    let [isLoadingServiceBook, setIsLoadingServiceBook] = useState(false);
    let [changeCarNumberModalOpen, setChangeCarNumberModalOpen] = useState(false);
    let [newCarNumber, setNewCarNumber] = useState('');
    let [newCarNumberError, setNewCarNumberError] = useState(false);
    let [deleteCarModalOpen, setDeleteCarModalOpen] = useState(false);

    let [changeCarTransponderNumberModalOpen, setChangeCarTransponderNumberModalOpen] = useState(false);
    let [newCarTransponderNumber, setNewCarTransponderNumber] = useState('');
    let [newCarTransponderNumberError, setNewCarTransponderNumberError] = useState(false);

    let [changeCarTransponderDogovorNumberModalOpen, setChangeCarTransponderDogovorNumberModalOpen] = useState(false);
    let [newCarTransponderDogovorNumber, setNewCarTransponderDogovorNumber] = useState('');
    let [newCarTransponderDogovorNumberError, setNewCarTransponderDogovorNumberError] = useState(false);

    let load = ()=>{
        setIsLoading(true);
        api()
            .getCars()
            .then(resp=>{
                let carsFromServer = resp.map(car => ({
                    id: car.CarId,
                    image: car.CarImage == null ? '/images/empty-car-image.svg' : 'data:image/png;base64,' + car.CarImage,
                    haveImage: car.CarImage !== null,
                    name: car.CarDescr,
                    vin: car.VinNumber,
                    number: car.LicencePlate,
                    color: car.ColorDescr,
                    startGuaranty: car.WarrantyBeginDate,
                    mark: car.ServiceEventInfo.length > 0,//car.HasServiceEvent,
                    hasSurveySSI: car.HasSurveySSI,//car.HasServiceEvent,
                    serviceEvents: car.ServiceEventInfo.map(s => ({
                        number: s.DocNumber,
                        title: s.Header
                    })),
                    isOwner: car.IsOwner,
                    ownerDescr: car.OwnerDescr,
                    transponderNumber: car.TransponderNumber || undefined,
                    transponderDogovorNumber: car.TransponderDogovorNumber || undefined,
                    maintenances: car.MaintenanceInfo.map(m => {
                        let newVar = {
                            name: `ТО ${m.ColumnNumber}`,
                            mileage: m.CarPathQnt*1000,
                            age: m.MonthQnt,
                            state: (m.IsWarning ? 'await' : '') + (m.IsMissed ? 'missing' : '') + (m.IsPassed ? 'complete' : ''), // 'missing' | 'complete' | 'await'
                            imageName: (m.IsWarning ? 'await-label' : '') + (m.IsMissed ? 'missing-label' : '') + (m.IsPassed ? 'done' : ''), // 'missing-label' | 'done' | 'await-label'
                        };

                        return newVar;
                    })
                }));
                setCars(carsFromServer);
                setIsLoading(false);
            });
    };

    useEffect(()=>{
        load();
    }, []);

    let downloadServiceBook = (id)=>{
        setIsLoadingServiceBook(true);
        api()
            .getServiceBook(id)
            .then(file => {
                if (!file.ok) {
                    setIsLoadingServiceBook(false);
                    toast.error('Ошибка формирования сервисной книжки')

                    return;
                }
                file.blob().then(blob => {
                    let blobUrl = URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = "DigitalServiceBooklet.pdf";
                    link.innerHTML = "Click here to download the file";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setIsLoadingServiceBook(false);
                });
            });
    };

    const setNewCarInfoFields = (car) => {
        setNewCarNumber(car.number || '')
        setNewCarTransponderDogovorNumber(car.transponderDogovorNumber || '')
        setNewCarTransponderNumber(car.transponderNumber || '')
    }

    const clearNewCarInfoFields = () => {
        setNewCarTransponderNumber('');
        setNewCarTransponderNumberError(false);

        setNewCarTransponderDogovorNumber('');
        setNewCarTransponderDogovorNumberError(false);

        setNewCarNumber('');
        setNewCarNumberError(false);
    }

    const saveNewCarInfo = () =>{
        if (newCarNumber.length > 15) {
            setNewCarNumberError(true)
            return
        }
        if (newCarTransponderNumber.length > 200) {
            setNewCarTransponderNumberError(true)
            return
        }
        if (newCarTransponderDogovorNumber.length > 200) {
            setNewCarTransponderNumberError(true)
            return
        }

        api()
            .changeCarInfo(currentCarSelected, newCarNumber, newCarTransponderNumber, newCarTransponderDogovorNumber)
            .then(resp => {
                if (resp.ErrorInfo !== undefined && resp.ErrorInfo !== null && resp.ErrorInfo !== '') {
                    toast.error(resp.ErrorInfo);
                    return;
                }
                toast.success(resp.UserInfo);

                setChangeCarNumberModalOpen(false)
                setChangeCarTransponderNumberModalOpen(false)
                setChangeCarTransponderDogovorNumberModalOpen(false)

                load();
            });
    };

    let deleteCar = ()=>{
        const vinIsValid = vinToDelete.length === 6 && currentCarVin.endsWith(vinToDelete);

        if (!vinIsValid) {
            setCheckVinToDeleteError(true);

            return;
        }

        api()
            .deleteCar(currentCarSelected)
            .then(resp => {
                if(resp.ErrorInfo !== undefined && resp.ErrorInfo !== null && resp.ErrorInfo !== '') {
                    toast.error(resp.ErrorInfo);
                    return;
                }
                toast.success(resp.UserInfo);
                setDeleteCarModalOpen(false);
                load();
            });
    };

    return <div>
        <div className={'cars-page'}>
            <Grid className={'header'}>
                <Grid.Row>
                    <Grid.Column width={16} onClick={() => setCurrentCarSelected(null)}>
                        <h1>{currentCarSelected == null
                            ? 'Мои автомобили'
                            : <span><img src={window.config.theme === 'Dark' ? '/images/dark/arrow-back.svg' : '/images/arrow-back.svg'}/>Автомобиль</span>
                        }</h1>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {isLoading && <div className="loader">Loading...</div>}
            {currentCarSelected === null && <div className={'car-items'}>
                {cars.map(car => <div className={'car-item'} onClick={()=>{
                    setCurrentCarSelected(car.id);
                    setCurrentCarVin(car.vin);
                }}>
                    <div className={'image-container'}>
                        <img className={'car-img' + (car.haveImage ? '' : ' empty')} src={car.image}/>
                    </div>
                    <div className={'details-container' + (car.hasSurveySSI ? ' have-need-ssi-message' : '')}>
                        <div className={'car-name' + (car.mark ? ' mark' : '')}>{car.mark && <MarkElement/>}{car.name}</div>
                        <Grid className={'car-properties'}>
                            <Grid.Row>
                                <Grid.Column width={5}>VIN:</Grid.Column>
                                <Grid.Column width={8}>{car.vin}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={5}>Гос номер:</Grid.Column>
                                <Grid.Column width={8}>{car.number}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={5}>Цвет:</Grid.Column>
                                <Grid.Column width={8}>{car.color}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={5}>Начало гарантии:</Grid.Column>
                                <Grid.Column width={8}>{car.startGuaranty}</Grid.Column>
                            </Grid.Row>
                            {!car.isOwner && (
                                <Grid.Row>
                                    <Grid.Column width={5}>Владелец:</Grid.Column>
                                    <Grid.Column width={8}>{car.ownerDescr}</Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                        {car.hasSurveySSI && (
                            <div className={'need-ssi-message'}>
                                <img src={window.config.theme === 'Dark' ? '/images/dark/bell.svg' : '/images/bell.svg'}/>
                                Выполните оценку качества
                            </div>
                        )}
                        <a className={'view-car-link'}>
                            Подробнее
                            <img src={window.config.theme === 'Dark' ? '/images/dark/arrow-right.svg' : '/images/arrow-right.svg'}/>
                        </a>
                    </div>
                </div>)}
            </div>}

            {currentCarSelected !== null && ((()=>{
                let car = cars.filter(x=> x.id === currentCarSelected)[0];

                return <div className={'car-selected-container'}>
                    <div className={'car-selected-info'}>
                        {car.haveImage ? <img className={'car-img'} src={car.image} /> : <div className={'no-have-image-container'}>
                            <img className={'car-img'} src={car.image} />
                        </div>}
                        <div className={'details-container'}>
                            <div className={'car-name'}>{car.name}</div>
                            <Grid className={'car-properties'}>
                                <Grid.Row>
                                    <Grid.Column width={5}>VIN:</Grid.Column>
                                    <Grid.Column width={8}>{car.vin}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>Гос номер:</Grid.Column>
                                    <Grid.Column width={11} className={'car-number-container'}>
                                        {car.number}
                                        <Popup content={'Изменить гос.номер автомобиля'} trigger={<img
                                          onClick={() => {
                                              setNewCarInfoFields(car);
                                              setChangeCarNumberModalOpen(true);
                                          }}
                                          src={window.config.theme === 'Dark' ? '/images/dark/edit-btn.svg' : '/images/edit-btn.svg'}/>}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>Цвет:</Grid.Column>
                                    <Grid.Column width={8}>{car.color}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>Начало гарантии:</Grid.Column>
                                    <Grid.Column width={11}>{car.startGuaranty}</Grid.Column>
                                </Grid.Row>
                                {!car.isOwner && (
                                  <Grid.Row>
                                      <Grid.Column width={5}>Владелец:</Grid.Column>
                                      <Grid.Column width={11}>{car.ownerDescr}</Grid.Column>
                                  </Grid.Row>
                                )}
                                {window.config.shortName === 'Exeed.ru' && (
                                  <Grid.Row>
                                      <Grid.Column width={5}>№ транспондера:</Grid.Column>
                                      <Grid.Column width={11} className={'delete-btn-container'}>
                                          {car.transponderNumber}
                                          <Popup content={'Изменить номер транспондера'} trigger={<img
                                            onClick={() => {
                                                setNewCarInfoFields(car);
                                                setChangeCarTransponderNumberModalOpen(true);
                                            }}
                                            src={window.config.theme === 'Dark' ? '/images/dark/edit-btn.svg' : '/images/edit-btn.svg'}/>}
                                          />
                                      </Grid.Column>
                                  </Grid.Row>
                                )}
                                {window.config.shortName === 'Exeed.ru' && (
                                  <Grid.Row>
                                      <Grid.Column width={5}>№ договора:</Grid.Column>
                                      <Grid.Column width={11} className={'delete-btn-container'}>
                                          {car.transponderDogovorNumber}
                                          <Popup
                                            content={'Изменить номер договора транспондера'}
                                            trigger={(
                                              <img
                                                onClick={() => {
                                                    setNewCarInfoFields(car);
                                                    setChangeCarTransponderDogovorNumberModalOpen(true);
                                                }}
                                                src={window.config.theme === 'Dark' ? '/images/dark/edit-btn.svg' : '/images/edit-btn.svg'}
                                              />
                                            )}
                                          />
                                      </Grid.Column>
                                  </Grid.Row>
                                )}
                                <Grid.Row className={'delete-car-row'}>
                                    <Grid.Column width={2}>
                                        <div className={'delete-car-button'}>
                                            <img
                                              onClick={() => {
                                                  setDeleteCarModalOpen(true)
                                              }}
                                              src={window.config.theme === 'Dark' ? '/images/dark/delete-btn.svg' : '/images/delete-btn.svg'}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={14} className={'delete-car-label'}>Удаление автомобиля из
                                        личного кабинета</Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        {car.serviceEvents.map(serviceEvent => <div className={'need-go-to-service-container'}>
                            <div className={'title'}>Имеются невыполненные сервисные мероприятия</div>
                            <div className={'article'}>{serviceEvent.number}</div>
                            <div className={'description'}>{serviceEvent.title}</div>
                        </div>)}
                        {isLoadingServiceBook
                            ? (
                                <button className={'download-book-btn'}>
                                    <img src={'/images/service-book.svg'}/> Сервисная книжка формируется...
                                </button>
                            ) : (
                                <button
                                    className={'download-book-btn'}
                                    onClick={() => downloadServiceBook(car.id)}
                                >
                                    <img src={window.config.theme === 'Dark' ? '/images/dark/service-book.svg' : '/images/service-book.svg'}/> Скачать сервисную книжку
                                </button>
                            )}
                    </div>
                    <div className={'to-list-container'}>
                        <table className={'to-list'}>
                            {car.maintenances.length > 0 && <tr>
                                <th>Номер ТО</th>
                                <th>Пробег</th>
                                <th>Возраст</th>
                                <th>Выполнение</th>
                            </tr>}
                            {car.maintenances.map(maintenance=> <tr className={maintenance.state}>
                                <td>{maintenance.name}</td>
                                <td>{parseInt(maintenance.mileage).toLocaleString('ru-RU')}</td>
                                <td>{maintenance.age}</td>
                                <td><img src={'/images/'+maintenance.imageName+'.svg'}/></td>
                            </tr>)}
                        </table>
                        <button className={'create-to-request-btn'} onClick={()=>{navigate('/serviceRequest/' + car.id);onChangePage();}}>Запись на ТО</button>
                        {car.hasSurveySSI && <SsiContainer carId={car.id} onComplete={(message)=>{
                            toast.success(message == null || message == '' ? 'Опрос пройден' : message);
                            load();
                        }}/>}
                    </div>
                </div>
            })())}
        </div>

        <Modal
            onClose={() => setChangeCarNumberModalOpen(false)}
            onOpen={() => setChangeCarNumberModalOpen(true)}
            open={changeCarNumberModalOpen}
            className={'change-car-number-modal'}
        >
            <Modal.Content>
                <div className={'title'}>Введите гос номер</div>
                <CustomInput label={'Введите гос. номер'}
                             value={newCarNumber}
                             onChange={setNewCarNumber}
                             error={newCarNumberError}
                             onFocus={() => setNewCarNumberError(false)}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} onClick={() => {
                            setChangeCarNumberModalOpen(false);
                            clearNewCarInfoFields()
                        }}>Отмена</Grid.Column>
                        <Grid.Column width={8} onClick={() => {
                            if (newCarNumber === '' || newCarNumber.length > 15) {
                                setNewCarNumberError(true)
                                return
                            }
                            saveNewCarInfo()
                        }}>Сохранить</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>

        <Modal
            onClose={() => setChangeCarTransponderNumberModalOpen(false)}
            onOpen={() => setChangeCarTransponderNumberModalOpen(true)}
            open={changeCarTransponderNumberModalOpen}
            className={'change-car-number-modal'}
        >
            <Modal.Content>
                <div className={'title'}>Введите номер транспондера</div>
                <CustomInput label={'№ транспондера'}
                             value={newCarTransponderNumber}
                             onChange={setNewCarTransponderNumber}
                             error={newCarTransponderNumberError}
                             onFocus={() => setNewCarTransponderNumberError(false)}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} onClick={() => {
                            setChangeCarTransponderNumberModalOpen(false);
                            clearNewCarInfoFields()
                        }}>Отмена</Grid.Column>
                        <Grid.Column width={8} onClick={saveNewCarInfo}>Сохранить</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>

        <Modal
            onClose={() => setChangeCarTransponderDogovorNumberModalOpen(false)}
            onOpen={() => setChangeCarTransponderDogovorNumberModalOpen(true)}
            open={changeCarTransponderDogovorNumberModalOpen}
            className={'change-car-number-modal'}
        >
            <Modal.Content>
                <div className={'title'}>Введите номер договора</div>
                <CustomInput label={'№ договора'}
                             value={newCarTransponderDogovorNumber}
                             onChange={setNewCarTransponderDogovorNumber}
                             error={newCarTransponderDogovorNumberError}
                             onFocus={()=> setNewCarTransponderDogovorNumberError(false)}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} onClick={() => {
                            setChangeCarTransponderDogovorNumberModalOpen(false);
                            clearNewCarInfoFields()
                        }}>Отмена</Grid.Column>
                        <Grid.Column width={8} onClick={saveNewCarInfo}>Сохранить</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>

        <Modal
            onClose={() => setDeleteCarModalOpen(false)}
            onOpen={() => setDeleteCarModalOpen(true)}
            open={deleteCarModalOpen}
            className={'delete-car-modal'}
        >
            <Modal.Content>
                <div className={'title'}>Внимание!</div>
                <p>
                    <span>{'Подтвердите безвозвратное удаление автомобиля VIN '}</span>
                    <b>{currentCarVin}</b>
                    <span>{' из личного кабинета'}</span>
                </p>
                <CustomInput
                  label={'Введите последние 6 цифр VIN номера'}
                  value={vinToDelete}
                  onChange={(value) => {
                      setVinToDelete(value);
                      setCheckVinToDeleteError(false);
                  }}
                  error={checkVinToDeleteError}
                />
                {checkVinToDeleteError && <div className={'error'}>
                    *Введено неверное значение 6 последних цифр VIN номера
                </div>}
                <p>Удалить автомобиль?</p>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} onClick={() => {
                            setDeleteCarModalOpen(false);
                        }}>Нет</Grid.Column>
                        <Grid.Column width={8} onClick={deleteCar}>Да</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
    </div>;
}

