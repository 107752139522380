import './AllNews.css';
import {useNavigate} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import {useState, useEffect} from "react";
import {api} from "../../utils/api";

export default function AllNews() {
    let navigate = useNavigate();
    let [news, setNews] = useState([]);

    let loadNews = ()=>{
        api()
            .getAllNews()
            .then(resp=>{
                let newsFromServer = resp.map(newsItem => ({
                    id: newsItem.NewsId,
                    date: newsItem.NewsDate,
                    title: newsItem.Title,
                    isImportant: newsItem.IsImportant,
                    newsTypeDescription: newsItem.NewsCategoryDescr,
                    isNew: !newsItem.IsReaded,
                    //isNew: true,
                }));
                setNews(newsFromServer);
            })
    }

    useEffect(loadNews, []);

    let readAll = ()=>{
        api()
            .markAllAsRead()
            .then(loadNews);
    };

    return <div>
        <div className={'all-news-page'}>
          <Grid className={'header'}>
            <Grid.Row>
              <Grid.Column width={10} onClick={()=>{navigate('/news')}}>
                  <h1>
                      <img src={window.config.theme === 'Dark' ? '/images/dark/arrow-back.svg' : '/images/arrow-back.svg'}/>
                      Все новости
                  </h1>
              </Grid.Column>
                {news.filter(x=>x.isNew).length > 0 ?<Grid.Column width={6} style={{textAlign: 'right'}}> <Button onClick={readAll} className={'read-all'} >Все в архив</Button></Grid.Column> : ''}
            </Grid.Row>
          </Grid>
          <div className={'news-items'}>
            {news.map(newsItem => <div className={'news-item'} key={'all-news-news-item-' + newsItem.id}>
                <div onClick={()=>{
                    if(newsItem.isNew)
                        api()
                            .markAsRead(newsItem.id)
                            .then(loadNews);
                    else
                        api()
                            .unMarkNewsItem(newsItem.id)
                            .then(loadNews);
                }} className={'mark-element' + (newsItem.isNew ? ' visible': '')}/>
                <div onClick={()=>{navigate('/news-item/' + newsItem.id);}} className={'title'}>{newsItem.title}</div>
                {newsItem.link !== undefined && newsItem.link !== '' ? <div className={'link-container'}><img src={'/images/link.svg'}/><a href={newsItem.link} target={'_blank'}><span className={'for-desktop'}>Подробнее</span></a></div> : ''}
                <div className={'news-time-container'} style={{textAlign: 'right'}}><img className={'for-desktop'} src={'/images/clock-time.svg'}/> {newsItem.date}</div>
            </div>)}
          </div>
        </div>
    </div>;
}
