import './ServiceCalc.css';
import {useWindowSize} from "../../utils/useWindowSize";
import {useEffect, useState} from "react";
import {api} from "../../utils/api";

export default function ServiceCalc({userFirstName, userLastName, dealerId}) {
  let [data, setData] = useState(null);
  let [cars, setCars] = useState([]);
  let windowSize = useWindowSize();
  let calcHost = window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : (window.location.hostname === 'lk.chery.ru'
          ? 'https://calc.chery.ru/'
          : 'https://service-calculator.test.logicstars.ru/');

  useEffect(()=>{
    api()
        .getCars()
        .then(resp=>{
            let cars = resp.map(x=>({
                carId: x.CarId,
                isOwner: x.IsOwner,
                ownerDescr: x.OwnerDescr,
                vin: x.VinNumber,
                name: x.CarDescr,
                number: x.LicencePlate,
                familyId: x.FamilyId,
                versionId: x.VersionId,
            }));
            setCars(cars);
        });
  }, []);

  useEffect(()=>{
      setData(data => {
          if(data == null)
              data = {};
          data.user = {
              firstName: userFirstName,
              lastName: userLastName,
              dealerId: dealerId
          };
          return data;
      });
  }, [userFirstName])

  return (
    <div className={'service-calc-page'}>
        {data == null || data.user === undefined || data.user.firstName === ''|| cars.length === 0
            ? <div className="loader">Loading...</div>
            : <iframe
                src={calcHost + '/?data=' + JSON.stringify(((() => {
                    let newVar = {};
                    newVar.user = data.user;
                    newVar.cars = cars;
                    return newVar;
                })()))}
              style={{height: (windowSize.height - 7) + 'px'}}>
            </iframe>}
    </div>
  );
}
