import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Login from "./pages/Login/Login";
import News from "./pages/News/News";
import Cars from "./pages/Cars/Cars";
import History from "./pages/History/History";
import ServiceRequest from "./pages/ServiceRequest/ServiceRequest";
import Messages from "./pages/Messages/Messages";
import Dealer from "./pages/Dealer/Dealer";
import Settings from "./pages/Settings/Settings";
import Register from "./pages/Register/Register";
import Restore from "./pages/Restore/Restore";
import AllNews from "./pages/AllNews/AllNews";
import Menu from "./menu/Menu";
import {useEffect, useState} from "react";
import {api} from "./utils/api";
import AutoLogin from "./pages/AutoLogin/AutoLogin";
import Publications from "./pages/Publications/Publications";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewsItem from "./pages/NewsItem/NewsItem";
import PersonalDataModal from "./shared/PersonalDataModal/PersonalDataModal";
import MessageAboutEmptyLogin from "./shared/MessageAboutEmptyLogin/MessageAboutEmptyLogin";
import CardImage from "./shared/CardImage/CardImage";
import ServiceCalc from "./pages/ServiceCalc/ServiceCalc";

export default function App() {
    let [userId, setUserId] = useState('');
    let [userGender, setUserGender] = useState('');
    let [userFirstName, setUserFirstName] = useState('');
    let [userLastName, setUserLastName] = useState('');
    let [userLogin, setUserLogin] = useState('');
    let [phone, setPhone] = useState('');
    let [email, setEmail] = useState('');
    
    let [menuLastUpdateTime, setMenuLastUpdateTime] = useState(new Date());
    
    let [modifyTemplatesMode, setModifyTemplatesMode] = useState(false);
    let [viewPersonalDataModal, setViewPersonalDataModal] = useState(false);
    
    let [userPersonalDealerId, setUserPersonalDealerId] = useState(null);
    let [userPersonalDealerName, setUserPersonalDealerName] = useState('');
    let [viewMessageAboutEmptyLogin, setViewMessageAboutEmptyLogin] = useState(false);

    let hideMenuInUrls = ['/', '/register', '/restore', '/Account/Login', '/card'];

    let loadClientInfo = ()=>{
        if(hideMenuInUrls.includes(window.location.pathname))
            return;
        api()
            .getUserInfo()
            .then((resp)=>{
                setUserId(resp.ClientId);
                setUserGender(resp.Sex);
                setUserFirstName(resp.Descr.split(' ')[1]);
                setUserLastName(resp.Descr.split(' ')[0]);
                setUserPersonalDealerId(resp.PersonalDealerId);
                setUserPersonalDealerName(resp.PersonalDealerDescr);
                setUserLogin(resp.Login);
                setPhone(resp.ContactPhone);
                setEmail(resp.Email);
                setModifyTemplatesMode(resp.ModifyTemplatesMode === 1);
                setViewPersonalDataModal(resp.PersonalInfoEnable === false);
                setViewMessageAboutEmptyLogin(resp.Login === null || resp.Login === '');
            });
    };
    useEffect(()=>{
        if(window.location.pathname === '/' && localStorage.getItem('AccessToken') != null){
            window.location = '/news';
        }
        else if(!hideMenuInUrls.includes(window.location.pathname)){
            loadClientInfo();
            let currentTime = (new Date).getTime();
            let savedLastTrackTime = localStorage.getItem('lastTrackTime');
            if(savedLastTrackTime === undefined || savedLastTrackTime === null || (currentTime - savedLastTrackTime) > 6*60*60*1000){
                setTimeout(()=>{
                    let trackAccessMode = localStorage.getItem('TrackAccessMode');
                    api()
                        .trackAccess(trackAccessMode === null ? 1 : trackAccessMode)
                        .then(resp=>{
                            localStorage.setItem('lastTrackTime', currentTime);
                        });
                }, 2000);
            }
        }
        
    }, []);
    
    let onChangePage = ()=>{
        setMenuLastUpdateTime(new Date());
    };
    
    return (
        <div>
            {viewMessageAboutEmptyLogin ? <MessageAboutEmptyLogin /> : ''}
            <div style={{position: viewMessageAboutEmptyLogin ? 'relative' : 'inherit'}}>
                <BrowserRouter>

                    <Menu
                        userId={userId}
                        userGender={userGender}
                        userFirstName={userFirstName}
                        userLastName={userLastName}
                        lastUpdateTime={menuLastUpdateTime}
                    />
                    <Routes>
                        <Route path="/" element={<Login afterLogin={loadClientInfo}/>}/>
                        <Route path="/Account/Login" element={<AutoLogin afterLogin={loadClientInfo}/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/restore" element={<Restore/>}/>
                        <Route path="/publications" element={<Publications modifyTemplatesMode={modifyTemplatesMode}/>}/>
                        <Route path="/news" element={<News/>}/>
                        <Route path="/index.html" element={<News/>}/>
                        <Route path="/news-item/:id" element={<NewsItem/>}/>
                        <Route path="/all-news" element={<AllNews/>}/>
                        <Route path="/cars" element={<Cars onChangePage={onChangePage}/>}/>
                        <Route path="/history" element={<History/>}/>
                        <Route path="/history/:id" element={<History/>}/>
                        <Route path="/Service/CSI/:id" element={<History/>}/>
                        <Route path="/serviceRequest/:car" element={<ServiceRequest
                            userPersonalDealerId={userPersonalDealerId}
                            userPersonalDealerName={userPersonalDealerName}
                            onChangeDealer={loadClientInfo}/>}/>
                        <Route path="/serviceRequest" element={<ServiceRequest
                            userPersonalDealerId={userPersonalDealerId}
                            userPersonalDealerName={userPersonalDealerName}
                            onChangeDealer={loadClientInfo}/>}/>

                        <Route path="/messages" element={<Messages
                            userPersonalDealerId={userPersonalDealerId}
                            userPersonalDealerName={userPersonalDealerName}
                            onChangeDealer={loadClientInfo}/>}/>}/>

                        <Route path="/dealer" element={<Dealer onChangeDealer={loadClientInfo}/>}/>
                        <Route path="/settings" element={<Settings 
                            userId={userId}
                            phone={phone}
                            email={email}
                            userFirstName={userFirstName} 
                            userLastName={userLastName} 
                            initLogin={userLogin}
                            onChangeLogin={loadClientInfo}
                        />}/>
                        <Route path="/roadHelp" element={<CardImage />}/>
                        <Route path="/calc" element={<ServiceCalc userFirstName={userFirstName}
                                                                  userLastName={userLastName}
                                                                  dealerId={userPersonalDealerId}
                        />}/>
                    </Routes>
                    <ToastContainer />
                    <PersonalDataModal
                        canClose={false}
                        openPersonalDataModal={viewPersonalDataModal}
                        setAgreePersonalData={()=>{
                            api()
                                .setPersonalDataAgree()
                                .then(()=>{
                                    window.location = '/';
                                })
                        }}
                        onCancel={()=>{
                            localStorage.clear();
                            window.location = '/';
                        }}
                    />
                </BrowserRouter>
            </div>
        </div>
    );
}
