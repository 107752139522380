import './Restore.css';
import {Form} from "semantic-ui-react";
import {useRef, useState} from "react";
import {useWindowSize} from "../../utils/useWindowSize";
import { captchaSettings, Captcha } from 'reactjs-captcha';
import {api} from "../../utils/api";

export default function Restore() {
  let [captchaCode, setCaptchaCode] = useState('');
  let [email, setEmail] = useState('');
  let [emailError, setEmailError] = useState(false);
  let [emailErrorMessage, setEmailErrorMessage] = useState('');
  let [captchaCodeError, setCaptchaCodeError] = useState(false);

  let [viewModalAfterSendEmail, setViewModalAfterSendEmail] = useState(false);

  let size  = useWindowSize();
  let captcha = useRef();

  let sendRequest = ()=>{
    if(captchaCode === '')
      setCaptchaCodeError(true);

    if(email === '')
      setEmailError(true);

    if(email === '' || captchaCode === '')
      return;

    let captchaId = captcha.current.getCaptchaId();
    api()
        .restore(captchaId, captchaCode, email)
        .then(resp => {
          if(!resp.captchaIsOk || !resp.emailIsOk) {
            setEmailError(!resp.emailIsOk);
            if(!resp.emailIsOk)
              setEmailErrorMessage('Email не найден или не допускает использования для восстановления доступа');
            setCaptchaCodeError(!resp.captchaIsOk);
            return;
          }
          setEmailError(false);
          setCaptchaCodeError(false);
          setViewModalAfterSendEmail(true);
        })

  };
  return <div className={'restore-page'} style={{backgroundImage: 'url("/images/' + window.config.restoreImgName + '.png")'}}>
    <div className={'right-panel white-paper'}></div>
    <div className={'right-panel blur'}></div>

    <div className={'right-panel restore-panel'} style={{height: size.height + 'px', overflowY: 'scroll'}}>
      <div className={'go-back-message'}>Уже восстановили доступ? <a href={'/'}>Войти</a></div>
      {viewModalAfterSendEmail ? <div>
        <h2>На указанный адрес было отправлено письмо.</h2>
        <p>Для восстановления доступа в Личный Кабинет следуйте указаниям, содержащимся в данном письме.</p>
      </div> : ''}
      {!viewModalAfterSendEmail ? <div><h1>Восстановление доступа</h1><Form>
        <Form.Field>
          <label>Указанный ранее E-mail</label>
          <input
              placeholder={'example@text.com'}
              value={email}
              onChange={e=> setEmail(e.target.value)}
              onFocus={()=>{setEmailError(false)}}
              className={(emailError ? 'error' : '')}
          />
        </Form.Field>
        {emailErrorMessage !== '' ? <div className={'email-error-message'}>{emailErrorMessage}</div> : ''}

        <Form.Field>
          <label>Проверочный код</label>
        </Form.Field>
        <div className={'captcha-container'}>
          {(()=>{
            captchaSettings.set({
              captchaEndpoint:
                  (window.location.hostname === 'localhost' ? `https://${window.location.hostname}:7217` : `https://${window.location.hostname}`) +
                  '/BotDetectCaptcha.ashx'
            });
            return <Captcha captchaStyleName="yourFirstCaptchaStyle" ref={captcha} />;
          })()}
        </div>
        <Form.Field>
          <input id="yourFirstCaptchaUserInput"
                 placeholder={'Код с картинки'}
                 value={captchaCode}
                 onChange={e=> setCaptchaCode(e.target.value)}
                 onFocus={()=>{setCaptchaCodeError(false)}}
                 className={(captchaCodeError ? 'error' : '')}
          />
        </Form.Field>
        <button onClick={sendRequest} className={'submit'}>Отправить запрос</button>
      </Form></div> : ''}
    </div>
  </div>;
}
