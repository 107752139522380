import {useEffect, useState} from "react";

import './MarkElement.css';

export default function MarkElement() {
    let [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible(isVisible => !isVisible);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return <div className={'mark-element' + (isVisible ? ' visible': '')}></div>;
}
