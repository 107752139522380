import './Messages.css';
import {Grid, Rating} from "semantic-ui-react";
import {useEffect, useMemo, useRef, useState} from "react";
import YearSelector from "../History/components/YearSelector/YearSelector";
import MarkElement from "../Cars/components/MarkElement";
import {useWindowSize} from "../../utils/useWindowSize";
import {api} from "../../utils/api";
import NewChatModal from "./components/NewChatModal/NewChatModal";
import CloseChatModal from "./components/CloseChatModal/CloseChatModal";
import {toast} from "react-toastify";
import ReopenChatModal from "./components/ReopenChatModal/ReopenChatModal";


export default function Messages({userPersonalDealerId, userPersonalDealerName, onChangeDealer}) {
  let getYearsInit = ()=>{
    let currentYear = new Date().getFullYear();
    let result = [];
    for(let i = 0; i < 11; i++) {
      result.push(currentYear - i);
    }
    return result;
  };
  let size = useWindowSize();
  let [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  let [years, setYears] = useState(getYearsInit());
  let [activeChatId, setActiveChatId] = useState(null);
  let [chats, setChats] = useState([]);

  let [openNewChatModal, setOpenNewChatModal] = useState(false);
  let [showCloseChatModal, setShowCloseChatModal] = useState(false);
  let [showReopenChatModal, setShowReopenChatModal] = useState(false);
  let [message, setMessage] = useState('');

  let [messages, setMessages] = useState([]);
  let listEl = useRef();

  const selectedChatStatus = useMemo(() => {
    return chats.filter(x=>x.id === activeChatId)[0]?.stateId
  }, [chats, activeChatId])

  let setCurrentYearAndReload = (val)=>{
    setCurrentYear(val);
    setActiveChatId(null);
    loadChats(val);
  }

  let loadChats = (year)=>{
    api()
        .getChats(year ?? currentYear)
        .then(chats=> {
          setChats(chats.map(chat=> ({
            id: chat.FeedbackId,
            carName: chat.ModelDescr,
            type: chat.MainFeedbackTypeDescr,
            subType: chat.SubFeedbackReasonDescr,
            department: chat.FeedbackDirectionDescr,
            date: chat.DocumentDate,
            state: chat.StatusDescr,
            stateId: chat.StatusId,
            dealer: chat.DealerDescr,
            haveNotReadMessages: chat.HasNewMessages,
            totalMessageCount: chat.MessagesCount,
            rating: chat.ClientRating,
          })));
        })
  };

  useEffect(()=>{
    loadChats();
  }, []);

  let clickToChat = (id) => {
    api()
        .getChatMessages(id)
        .then(messages => {
          setMessages(messages.map(message => ({
            id: message.RowId,
            type: message.IsDealer ? 'in' : 'out',
            text: message.Descr,
            date: message.DateInsert
          })));
          setActiveChatId(id);
        });
  };

  let send = () => {
    setTimeout(() => {
      listEl.current.scrollTo(0, 10000)
    }, 1)

    api()
        .sendMessage(activeChatId, message)
        .then(resp=>{
          if(resp.ErrorInfo === null || resp.ErrorInfo === ''){
            let temp = messages.map(x=>x);

            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1;
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            const formattedToday = dd + '.' + mm + '.' + yyyy;
            temp.push({id: 2, type: 'out', text: message, date: formattedToday});
            setMessages(temp);
            return;
          }
          toast.error(resp.ErrorInfo);
        }).finally(() => setMessage(''));
  };

  let approveClosingChat = () => {
    setShowCloseChatModal(true);
  }

  let rejectClosingChat = () => {
    setShowReopenChatModal(true);
  }

  let chatsList = <div className={'chats-container'}>
    {chats.map(chatItem => {
      const needClose = chatItem.stateId === 3;
      return <div
          key={chatItem.id}
          className={'chat-item' + (chatItem.id === activeChatId ? ' active' : '')}
          onClick={()=>{clickToChat(chatItem.id)}}
      >
        <div className={'chat-item-inner'}>
          <div className={'type'}>{chatItem.carName}</div>
          <div className={'department'}>{chatItem.department} | <span style={{color: '#23B062'}}>{chatItem.type}</span>
          </div>
          <div className={'sub-type'}>{chatItem.subType}</div>
          {chatItem.rating !== null && (
            <Rating
              icon='star'
              rating={chatItem.rating}
              maxRating={chatItem.rating}
              className={'rating'}
              size='tiny'
              disabled
            />
          )}

          <div className={'date'}>{chatItem.date}</div>
          <div className={'state'}>{chatItem.state}</div>
          <div className={`messages-count ${needClose ? 'need-close' : ''}`}>
            <img
              src={`/images/${window.config.theme === 'Dark' ? 'dark/' : ''}new-message-icon.svg`}/> {chatItem.totalMessageCount} {chatItem.haveNotReadMessages &&
            <MarkElement/>}
          </div>
          {needClose && (
            <div className={'need-close-icon'}/>
          )}
        </div>
      </div>;
    })}
  </div>;

  let messagesContainer = chats.length > 0 ? (activeChatId == null ? <div className={'chat-container'}>
    <div
      style={{height: (size.height - 295) + 'px'}}
      className={'select-chat-message'}>Выберите сообщение
    </div>
  </div> : <div className={'chat-container'}>
    <div className={'dealer-chat-info'}>
      <span style={{color: '#868686'}}>Дилер</span> <span>{chats.filter(x=>x.id === activeChatId)[0].dealer}</span>
    </div>
    <div className={'chat-messages-container'} ref={listEl} style={{height: (size.height - 295) + 'px'}}>
      {messages.map(message => (
        <div key={message.id} className={'message ' + message.type}>
          <div className={'message-marker'}>
            <img src={`/images/${window.config.theme === 'Dark' ? 'dark/' : ''}` + message.type + '-message-marker.svg'}/>
          </div>
          {message.text}
          <div className={'date'}>
            {message.date}
          </div>
        </div>
      ))}
    </div>
    {![3, 4, 5].includes(selectedChatStatus) && (
        <div className={'create-message-block'}>
          <input
              className={'input-for-message'}
              value={message}
              onChange={e => setMessage(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter')
                  send();
              }}
          />
        <div className={'send-btn'} onClick={send}>
          <img src={window.config.theme === 'Dark' ? '/images/dark/send.svg' : '/images/send.svg'} />
        </div>
      </div>
    )}

    {selectedChatStatus === 3 && (
      <div className={'close-chat-block'}>
        <div style={{ color: window.config.theme === 'Dark' ? '#F5F5F5' : 'rgba(0, 0, 0, .87)' }}>
          Подтверждаете закрытие обращения к дилеру?
        </div>
        <div className={'close-chat-buttons'}>
          <button className={'button button-yes'} onClick={approveClosingChat}>ДА</button>
          <button className={'button button-no'} onClick={rejectClosingChat}>НЕТ</button>
        </div>
      </div>
    )}

  </div>) : '';
  return <div>
    <div className={'messages-page'}>
      <div className={'for-desktop'}>
        <Grid className={'header'}>
          <Grid.Row>
            <Grid.Column width={6}><h1>Сообщение дилеру</h1></Grid.Column>
            <Grid.Column width={10}>
              <YearSelector value={currentYear} years={years} onSelect={setCurrentYearAndReload}/>
              <button onClick={() => setOpenNewChatModal(true)}>
                <img src={window.config.theme === 'Dark' ? '/images/dark/create-message.svg' : '/images/create-message.svg'}/> Написать
              </button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              {chats.length > 0 ? chatsList : <div className={'select-chat-message'}>Сообщения для выбранного года отсутствуют</div>}
            </Grid.Column>
            <Grid.Column width={10}>
              {messagesContainer}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className={'for-mobile'}>
        {activeChatId == null ? <div>
          <Grid className={'header'}>
            <Grid.Row>
              <Grid.Column width={11}><h1>Сообщение дилеру</h1></Grid.Column>
              <Grid.Column width={5}>
                <button onClick={() => {
                  setOpenNewChatModal(true)
                }}><img src={'/images/create-message.svg'}/><span className={'create-message-text'}> Написать</span>
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className={'years-selector-container-block'}>
            <YearSelector value={currentYear} years={years} onSelect={setCurrentYearAndReload}/>
          </div>
          {chats.length > 0 ? chatsList : <div className={'select-chat-message'}>Сообщения для выбранного года отсутствуют</div>}
        </div> : <div>
          <div className={'chat-header'} onClick={()=>{setActiveChatId(null)}}>
            <h1><img src={'/images/arrow-back.svg'}/> {chats.filter(x=>x.id === activeChatId)[0].department}, {chats.filter(x=>x.id === activeChatId)[0].type}</h1>
          </div>
          {messagesContainer}
        </div>}
      </div>
    </div>

    <NewChatModal
        open={openNewChatModal}
        setOpen={setOpenNewChatModal}
        userPersonalDealerId={userPersonalDealerId}
        userPersonalDealerName={userPersonalDealerName}
        onCreateNewChat={loadChats}
        onChangeDealer={onChangeDealer}
    />

    <CloseChatModal
      open={showCloseChatModal}
      setOpen={setShowCloseChatModal}
      onCloseChat={loadChats}
      activeChatId={activeChatId}
    />

    <ReopenChatModal
      open={showReopenChatModal}
      setOpen={setShowReopenChatModal}
      onReopenChat={loadChats}
      activeChatId={activeChatId}
    />
  </div>;
}
