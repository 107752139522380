import './Login.css';
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {api} from "../../utils/api";
import Faq from "./components/Faq";

export default function Login({afterLogin}) {
  let [passwordIsVisible, setPasswordIsVisible] = useState(false);
  let [login, setLogin] = useState('');
  let [password, setPassword] = useState('');

  let [loginIsError, setLoginIsError] = useState(false);
  let [passwordIsError, setPasswordIsError] = useState(false);

  let [enterError, setEnterError] = useState(false);
  let [errorMessage, setErrorMessage] = useState('Неправильный логин или пароль');

  let [isOpenFaq, setIsOpenFaq] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  let onLoginClick = () => {
    if(login === '')
      setLoginIsError(true);
    if(password === '')
      setPasswordIsError(true);
    if(login === '' || password === '')
      return;
    if(isLoading)
      return;
    setIsLoading(true);
    api()
        .login(login, password)
        .then(resp => {
          setIsLoading(false);
          if(resp.AccessToken === null){
            setEnterError(true);
            setErrorMessage(resp.InfoMessage);
            return;
          }
          localStorage.setItem('AccessToken', resp.AccessToken);
          localStorage.setItem('RefreshToken', resp.RefreshToken);
          localStorage.setItem('UserId', resp.UserId);
          localStorage.setItem('TrackAccessMode', 1);
          api()
              .trackAccess(1)
              .then(()=>{
                localStorage.setItem('lastTrackTime', (new Date).getTime());
                navigate('/news');
                afterLogin();
              });
        })
        .catch(err=> {
          setIsLoading(false);
          setEnterError(true);
          setErrorMessage('Неправильный логин или пароль');
        });
  };

  return <div className={'login-page'} style={{backgroundImage: 'url("/images/' + window.config.loginImgName + '.png")'}}>
    <div className={'right-panel white-paper'}></div>
    <div className={'right-panel blur'}></div>
    <div className={'right-panel content-panel'}>
      {window.isMobile ? '' : (window.config.mobileAppLink === '' ? '' : <a href={window.config.mobileAppLink} target={'_blank'} className={'mobile-app-link'}><img src={'/images/mobile-app.svg'}/>Мобильное приложение</a>)}
      <h1>Войти</h1>
      <div>
        <input placeholder={'Логин'}
               value={login}
               onChange={e => setLogin(e.target.value)}
               onKeyDown={e=>{if (e.key === 'Enter' && login !== '' && password  !== '') {onLoginClick();}}}
               className={loginIsError ? 'error' : ''}
               onFocus={()=>{setLoginIsError(false); setEnterError(false);}}
        />
        {loginIsError ? <div className={'error-message'}>*Укажите логин</div> : ''}
        <div className={'password-input'}>
          <input
              placeholder={'Введите пароль'}
              type={passwordIsVisible  ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e=>{if (e.key === 'Enter' && login !== '' && password  !== '') {onLoginClick();}}}
              className={passwordIsError ? 'error' : ''}
              onFocus={()=>{setPasswordIsError(false); setEnterError(false);}}
          />
          <img src={'/images/'+(passwordIsVisible ? 'remove-' : '')+'eye.svg'} onClick={()=>{setPasswordIsVisible(!passwordIsVisible);}}/>
        </div>
        {passwordIsError ? <div className={'error-message'}>*Укажите пароль</div> : ''}
      </div>
      <div className={'recovery-password-link-container'}><a href={'/restore'}>Забыли пароль?</a></div>
      {enterError ? <div className={'error-message'} style={{paddingLeft: '0'}}>{errorMessage}</div> : ''}
      <button className={'login-btn'} onClick={onLoginClick}>Войти</button>
      <p>У вас нет аккаунта? <a href={'/register'}><b>Зарегистрироваться</b></a></p>
    </div>
    <div className={'open-faq-btn'} onClick={()=>{
      setIsOpenFaq(true);
    }}>
      <img src={window.config.theme === 'Dark' ? '/images/faq-white.svg' : '/images/faq-blue.svg'}/> Вопросы и ответы
    </div>
    <div className={'link-to-main-site-container'}>
      <a href={'https://' + window.config.mainDomain} target={'_blank'}>https://{window.config.mainDomain}/</a>
    </div>
    <Faq isOpen={isOpenFaq} setOpen={setIsOpenFaq} />
  </div>;
}
