import './SelectDealerModal.css';
import {Grid, Icon, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {api} from "../../utils/api";

export default function SelectDealerModal({open, setOpen, onChange, dealerName}) {
    let [dealers, setDealers] = useState([]);
    let [search, setSearch] = useState('');


    useEffect(()=>{
        if(open)
            api()
                .getDealerList()
                .then(list => {
                    setDealers(list.map(x=>({id: x.DealerPointId, name: x.Descr, address: x.Address, active: x.Descr === dealerName})));
                });
    }, [open]);

    let setMyDealer = (id) => {
        api()
            .setMyDealer(id)
            .then(resp=> {
                setOpen(false);
                onChange();
            });
    };

    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        className={'select-dealer-modal'}
    >
    <Modal.Content>
        <div className={'modal-title'}>Список дилеров</div>
        <div className={'search-input-container'}>
            <input className={'search'} value={search} onChange={e => setSearch(e.target.value)}/>
            <Icon name={'search'} color={'grey'}/>
        </div>
        <Grid className={'dealer-list'}>
            {dealers
                .filter(x=> (search === '' ? true : ((x.name !== null && x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (x.address !== null && x.address.toLowerCase().indexOf(search.toLowerCase()) !== -1))))
                .map(dealer => {
                return <Grid.Row
                      onClick={()=>{setMyDealer(dealer.id)}}
                      className={'dealer-item' + (dealer.active ? ' active' : '')}>
                    <Grid.Column width={8} className={'dealer-name'}>{dealer.name}</Grid.Column>
                    <Grid.Column width={8} className={'dealer-address'}>{dealer.address}</Grid.Column>
                </Grid.Row>;
            })}
        </Grid>
    </Modal.Content>
    </Modal>;
}
