import './PersonalDataModal.css';
import {Grid, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {api} from "../../utils/api";

export default function PersonalDataModal({canClose=true, openPersonalDataModal, setOpenPersonalDataModal, setAgreePersonalData, onCancel}) {
    let [personalConfirmationText, setPersonalConfirmationText] = useState('');

    useEffect(()=>{
        api()
            .getPersonalDataText()
            .then(resp => {
                setPersonalConfirmationText(resp.Descr);
            });
    }, []);

    return <Modal
        onClose={() => setOpenPersonalDataModal(false)}
        onOpen={() => setOpenPersonalDataModal(true)}
        open={openPersonalDataModal}
        closeIcon={canClose}
        closeOnEscape={canClose}
        closeOnTriggerBlur={canClose}
        className={'personal-data-modal'}
    >
        <Modal.Content>
            <div>
                {personalConfirmationText.split('\r\n').map((line, i) => (
                    <p key={i}>
                        {line}
                    </p>
                ))}
            </div>
            <Grid style={{marginTop: '50px'}}>
                <Grid.Row>
                    <Grid.Column width={6} style={{textAlign: 'left'}}>
                        <button onClick={() => {if(onCancel !== undefined) {onCancel();} setOpenPersonalDataModal(false);}}>
                            Отмена
                        </button>
                    </Grid.Column>
                    <Grid.Column width={10} style={{textAlign: 'right'}}>
                        <button
                            onClick={() =>{ setAgreePersonalData(true); setOpenPersonalDataModal(false); }}
                        ><img src={'/images/done-btn.svg'}/> Я согласен (на)</button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
    </Modal>;
}
