import './CustomCheckbox.css';

export default function CustomCheckbox({value, label, onChange, image, color, isComplete}) {
  const getCheckboxRoute = () => window.config.theme === 'Dark' ? '/images/dark/chekbox-' : '/images/chekbox-'

  return (
      <div
          className={'custom-checkbox-container' +
              (image !== undefined || color !== undefined  ? ' with-image-or-color' : '') +
              (isComplete ? ' is-complete' : '')}
          onClick={() => onChange(!value)}>
        <img className={'checkbox-image'} src={(getCheckboxRoute() + (value ? 'true' : 'false') +'.svg')}/>
        {image && <img className={'image-for-item'} src={image}/>}
        {color && <div className={'color-for-item'} style={{backgroundColor: color}}/>}
        {label}
      </div>
  )
}
