import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Message} from "semantic-ui-react";

import {api} from "../../utils/api";
import QuestionScaleInput from "../../pages/History/components/QuestionScaleInput/QuestionScaleInput";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomRadio from "../CustomRadio/CustomRadio";

import './SsiContainer.css';

/**
 * @returns {JSX.Element|string}
 * По типам вопросов - см utils/Survey/SurveyAnswerTypeEnum
 */
export default function SsiContainer({ carId, onComplete }) {
  let [headerText, setHeaderText] = useState('');
  let [questions, setQuestions] = useState([]);
  let isComplete = false;

  useEffect(() => {
    api()
      .getSsiQuestions(carId)
      .then((resp) => {
        setHeaderText(resp.HeaderText);

        let primary = resp.PrimaryQuestionModelList.map((q) => {
          return {
            id: q.QuestionId,
            title: q.Descr,
            type: q.AnswerTypeId,
            answer: q.HappyIndex ?? null,
            variants: resp.PrimaryAnswerModelList
              .filter((x) => x.QuestionId === q.QuestionId)
              .map((possibleAnswer) => ({
                id: possibleAnswer.AnswerId,
                title: possibleAnswer.Descr,
                isChecked: possibleAnswer.IsChecked,
                openTextFieldIfChecked: possibleAnswer.IsTextAnswer,
                text: possibleAnswer.TextValue ?? undefined
              }))
          }
        });

        let result = [];

        primary.map((primaryItem) => {
          result.push(primaryItem);

          resp.SecondaryQuestionModelList
            .filter((x) => x.MasterQuestionId === primaryItem.id)
            .map((q) => {
              result.push({
                id: q.QuestionId,
                parentId: q.MasterQuestionId,
                type: 3, // SurveyAnswerTypeEnum.SECONDARY_MULTIPLE_CHOICE
                viewIfParentIn: [q.HappyIndexMin, q.HappyIndexMax],
                title: q.Descr,
                answer: null,
                variants: resp.PossibleAnswerModelList
                  .filter((x) => x.QuestionId === q.QuestionId)
                  .map((possibleAnswer) => ({
                    id: possibleAnswer.AnswerId,
                    title: possibleAnswer.Descr,
                    isChecked: possibleAnswer.IsChecked,
                    openTextFieldIfChecked: possibleAnswer.IsTextAnswer,
                    text: possibleAnswer.TextValue ?? undefined
                  }))
              });
            });
        })

        setQuestions(result);
      })
  }, []);

  let getParentAnswer = (question) => {
    return questions.filter((x) => x.id === question.parentId)[0].answer;
  }

  let getRadioCheckedValue = (q) => {
    let result = -1;

    q.variants.forEach((variant, index) => {
      if (variant.isChecked) {
        result = index + 1;
      }
    });

    return result;
  };

  let sendAnswersToServer = () => {
    let happyIndexData = '';

    questions
      .filter((x) => x.parentId === undefined && x.type !== 3)
      .forEach((x) => {
        happyIndexData += x.id + ','+ (x.type === 6 ? x.answer : '')+ (x.type === 2 ? getRadioCheckedValue(x) : '') + '|';
      })

    let primaryAnswersData = '';

    questions
      .filter((x) => x.parentId === undefined)
      .forEach((x) => {
        if (x.variants.length > 0) {
          x.variants.forEach((variant) => {
            primaryAnswersData += variant.id + ','+ (variant.isChecked ? '1' : '0') + ','+ (variant.text ?? '') + '|';
          });
        }
      });

    let answersData = '';

    questions
      .filter((x) => x.parentId !== undefined)
      .forEach((x) => {
        if (x.variants.length > 0) {
          x.variants.forEach((variant) => {
            answersData += variant.id + ','+ (variant.isChecked ? '1' : '0') + ','+ (variant.text ?? '') + '|';
          });
        }
      });

    api()
      .setSsiAnswers(carId, happyIndexData, primaryAnswersData, answersData)
      .then((resp) => {
        if (resp.ErrorInfo === '') {
          onComplete();
        } else {
          toast.error(resp.ErrorInfo);
        }
      });
  };

  // Renders
  if (!questions || !questions.length) {
    return null
  }
  return (
    <div className={'ssi-container quality-assessment-container'}>
      <Message style={{whiteSpace: "pre-wrap"}}>{headerText}</Message>

      {questions.map((question, index) => ((question.parentId === undefined ? true : getParentAnswer(question) >= question.viewIfParentIn[0] && getParentAnswer(question) <= question.viewIfParentIn[1]) && (
        <div>
          <div className={'question-title'}>
            {question.title}
          </div>

          {question.type === 3 && (
            <div className={'not-satisfied-variant'}>
              {question.variants.filter((x) => !(isComplete && !x.isChecked)).map((variant, variantIndex) => (
                <div>
                  <CustomCheckbox
                    isComplete={isComplete}
                    value={variant.isChecked}
                    label={variant.title}
                    onChange={(val) => {
                      if (isComplete) {
                        return;
                      }
                      let temp = questions.map((x) => x);
                      temp[index].variants[variantIndex].isChecked = val;
                      setQuestions(temp);
                    }}
                  />

                  {variant.openTextFieldIfChecked && variant.isChecked && (
                    <input
                      placeholder={'Введите...'}
                      className={'other-text-field-variant'}
                      disabled={isComplete}
                      value={variant.text}
                      onChange={(e) => {
                        if (isComplete) {
                          return;
                        }

                        let temp = questions.map((x) => {
                          if (x.id === question.id) {
                            x.variants = question.variants.map((varik) => {
                              if (varik.id === variant.id) {
                                varik.text = e.target.value;
                              }
                              return varik;
                            });
                            return x;
                          }
                          return x;
                        });

                        setQuestions(temp);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}

          {question.type === 6 && (
            <QuestionScaleInput
              isComplete={isComplete}
              value={question.answer}
              onChange={(answer) => {
                if (isComplete) {
                  return;
                }

                let temp = questions.map((x) => x);
                temp[index].answer = answer;
                setQuestions(temp);
              }}
            />
          )}

          {question.type === 2 && (
            <div className={'not-satisfied-variant'}>
              {question.variants.filter((x) => !(isComplete && !x.isChecked)).map((variant, variantIndex) => (
                <div>
                  <CustomRadio
                    isComplete={isComplete}
                    value={variant.isChecked}
                    label={variant.title}
                    onChange={(val) => {
                      if (isComplete) {
                        return;
                      }

                      let temp = questions.map((x) => x);
                      temp[index].variants.forEach((x) => x.isChecked = false);
                      temp[index].variants[variantIndex].isChecked = val;
                      setQuestions(temp);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )))}

      <button
        disabled={!(questions.filter((x) => x.parentId === undefined && x.type === 6).every((x) => x.answer !== null)
          && questions.filter((x) => x.parentId === undefined && (x.type === 3 || x.type === 2)).every((x) => x.variants.some((z) => z.isChecked)))}
        onClick={sendAnswersToServer}
        className={"finish-btn"}
      >
        Завершить опрос
      </button>
    </div>
  );
}
