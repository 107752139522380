import './CardImage.css';
import {useEffect, useState} from "react";
import {api} from "../../utils/api";
import {Grid} from "semantic-ui-react";
import Barcode from "react-barcode";
import {useWindowSize} from "../../utils/useWindowSize";

export default function CardImage({}) {
  let [cards, setCards] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let size  = useWindowSize();
  useEffect(()=>{
    setIsLoading(true);
    api()
        .getSARACards()
        .then(resp => {
          setCards(resp);
          setIsLoading(false);
        });
  }, []);
  let imageHeight = (size.width - 335) * 197 / 784;
  let imageHeightMobile = (size.width - 25) * 197 / 784;
  return <div className={'road-help-page'}>
      <Grid className={'header'}>
          <Grid.Row>
              <Grid.Column width={16}><h1>Помощь на дороге</h1></Grid.Column>
          </Grid.Row>
      </Grid>
      <div className={'road-help-page-container'}>
          {isLoading ? <div className="loader">Loading...</div> : ''}
          {cards.length === 0 && !isLoading ? <div className={'for-desktop'} style={{textAlign: 'center', paddingTop: '25px', height: (size.height - (100 + imageHeight)) + 'px'}}>
              Карты помощи отсутствуют
              <br/>
              <img src={window.config.theme == 'white' ? '/images/rat-empty.svg' : '/images/rat-empty-black.svg'} style={{width: '250px'}}/>
          </div> : ''}
          {cards.length === 0 && !isLoading ? <div className={'for-mobile'} style={{textAlign: 'center', paddingTop: '25px', height: (size.height - (60 + imageHeightMobile)) + 'px'}}>
              Карты помощи отсутствуют<br/>
              <img src={window.config.theme == 'white' ? '/images/rat-empty.svg' : '/images/rat-empty-black.svg'} style={{width: '100%'}}/>
          </div> : ''}
          {cards.map(card => <div className={'card-container'}>
              <Grid className={'for-desktop'}>
                  <Grid.Row>
                      <Grid.Column width={8} className={'card-description'}>
                          <div>VIN: {card.Vin}</div>
                          <div>Действует до: {new Date(Date.parse(card.ExpireDate)).toLocaleDateString()}</div>
                          {!card.IsOwner && <div>Владелец: {card.OwnerDescr}</div>}
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                      <Grid.Column width={8}>
                          <div className={'card-image'}>
                              <img src={'data:image/png;base64, ' + card.FrontImageBase64}/>
                              <div className={'card-number'}>{card.CardNumber}</div>
                          </div>
                          <div className={'card-image'}>
                              <img src={'data:image/png;base64, ' + card.BackImageBase64}/>
                              <div className={'bar-code'}><Barcode value={card.CardNumber} width={1.5} height={15} margin={3.5}/></div>
                          </div>
                      </Grid.Column>
                      <Grid.Column width={8} style={{whiteSpace: "pre-wrap"}}>{card.Description}</Grid.Column>
                  </Grid.Row>
              </Grid>
              <div className={'for-mobile'}>
                  <div>VIN: {card.Vin}</div>
                  <div>Действует до: {new Date(Date.parse(card.ExpireDate)).toLocaleDateString()}</div>
                  {!card.IsOwner && <div>Владелец: {card.OwnerDescr}</div>}
                  <div>
                      <div className={'card-image'}>
                          <img src={'data:image/png;base64, ' + card.FrontImageBase64}/>
                          <div className={'card-number'}>{card.CardNumber}</div>
                      </div>
                      <div className={'card-image'}>
                          <img src={'data:image/png;base64, ' + card.BackImageBase64}/>
                          <div className={'bar-code'}><Barcode value={card.CardNumber} width={1.2} height={10} fontSize={12} margin={3.5}/></div>
                      </div>
                  </div>
                  <div style={{whiteSpace: "pre-wrap"}}>{card.Description}</div>
              </div>
          </div>)}
          {!isLoading ? <img src={window.config.theme == 'white' ? '/images/rat_footer.jpg' : '/images/rat_footer_fix.png'} style={{width: '100%'}}/> : ''}
      </div>
  </div>;
}
