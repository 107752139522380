import './CustomFileInput.css';
import {Button} from "semantic-ui-react";
import {useState} from "react";

export default function CustomFileInput({refToElement, onSetFile, error}) {
    let [fileName, setFileName] = useState('');
    const inputRef = refToElement;
    return <div className={'custom-file-input-container' + (error ? ' error' : '')}>
        <div className={'label-container'}>
            <div className={'label-name'}>{fileName === '' ? 'Файл не выбран' : fileName}</div>
            <Button style={{padding: '14px'}}><img src={'/images/upload-image.svg'}/></Button>
        </div>
        <input ref={inputRef} type={'file'} onChange={()=>{
            if(inputRef.current.files.length > 0){
                setFileName(inputRef.current.files[0].name);
            }else{
                setFileName('');
            }
            onSetFile();
        }}/>
    </div>;
}
