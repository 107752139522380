import './YearSelector.css';
import { useState } from 'react';

export default function YearSelector({ value, years, onSelect }) {
  let [position, setPosition] = useState(0);

  return (
    <div className={'year-selector'}>
      <img className={'calendar-img'} src={'/images/calendar.svg'} />

      <div className={'years-slice-container'}>
        <div className={'years-container'} style={{ marginLeft: position + 'px' }}>
          {years.map((year, index) => (
            <div
              key={index}
              onClick={() => {
                onSelect(year);
              }}
              className={'year-item ' + (value === year ? 'selected' : '')}
            >
              {year}
            </div>
          ))}
        </div>
      </div>

      {position === 0 ? (
        ''
      ) : (
        <div
          className={'move-to-left'}
          onClick={() => {
            setPosition(position + 100);
          }}
        >
          <img src={'/images/simple-arrow-left.svg'} />
        </div>
      )}
      
      {position < 0 - (years.length > 3 ? years.length : years.length - 3) * 23 ? (
        ''
      ) : (
        <div
          className={'move-to-right'}
          onClick={() => {
            setPosition(position - 100);
          }}
        >
          <img src={'/images/simple-arrow-right.svg'} />
        </div>
      )}
    </div>
  );
}
