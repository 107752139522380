import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify';

import { api } from '../../../../utils/api'
import CustomInput from "../../../../shared/CustomInput/CustomInput";

import './AddNewContactPersonForm.css'


const AddNewContactPersonForm = ({
  onClose,
  isUpdating,
  setIsUpdating,
  setContacts,
}) => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  // Effects
  useEffect(() => {
    if (phone.length) {
      const re = new RegExp(`^((8|\\+7)[\\- ]?)?(\\(?\\d{3,4}\\)?[\\- ]?)?[\\d\\- ]{5,10}$`)

      setPhoneError(!re.test(phone))
    } else {
      setPhoneError(false)
    }
  }, [phone])

  useEffect(() => {
    if (email.length) {
      const re = new RegExp(`^[\\w-\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`)

      setEmailError(!re.test(email))
    } else {
      setEmailError(false)
    }
  }, [email])

  // Handlers
  const createNewContact = () => {
    if (getCreateContactDisabledState()) {
      toast.error('Обязательные поля не заполнены');
      return
    }

    if (phoneError || emailError) {
      if (phoneError) {
        toast.error('Поле \"Телефон\" заполнено неверно');
      }
      if (emailError) {
        toast.error('Поле \"E-mail\" заполнено неверно');
      }
      return
    }


    const data = {
      name: name.trim(),
      surname: surname.trim(),
      email: email.trim(),
      phone: phone.trim().replace(/[^0-9]/g, ''),
    }

    setIsUpdating(true)

    api()
      .addContactPerson(data)
      .then((postResponse) => {
        if (!postResponse.errorInfo) {
          toast.success(`Контактное лицо \"${name} ${surname}\" успешно добавлено!`);

          api()
            .getContactPersonsList()
            .then((getResponse) => {
              setContacts(getResponse)
              setIsUpdating(false)
            })
        } else {
          setIsUpdating(false)
        }
        onClose()
      })
      .catch(() => {
        setIsUpdating(false)
      })
  }

  // Helpers
  const getCreateContactDisabledState = () => {
    return !name || !surname || !phone || !email
  }

  // Renders
  return (
    <div className={`add_new_contact__container`}>
      <Grid className={`add_new_contact__grid_container`}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className={'custom-title'}>
              Фамилия <span className="required">*</span>
            </div>

            <CustomInput
              label={`Фамилия`}
              onChange={setSurname}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <div className={'custom-title'}>
              Имя <span className="required">*</span>
            </div>

            <CustomInput
              label={`Имя`}
              onChange={setName}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className={'custom-title'}>
              Телефон <span className="required">*</span>
            </div>

            <InputMask
              className={'add_new_contact__phone-input' + (phoneError ? ' error' : '')}
              mask="+7 (999) 999-99-99"
              placeholder={`Телефон`}
              pattern="/^+7\d*"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className={'custom-title'}>
              E-mail <span className="required">*</span>
            </div>

            <CustomInput
              label={`E-mail`}
              type="email"
              error={emailError}
              onChange={setEmail}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <button className={`add_new_contact__btn add_new_contact__reject`} onClick={onClose}>
              Удалить
            </button>
          </Grid.Column>

          <Grid.Column width={8}>
            <button
              className={`add_new_contact__btn add_new_contact__agree ${(getCreateContactDisabledState() || phoneError || emailError) ? 'btn_disabled' : ''}`}
              style={{ backgroundColor: window.config.color }}
              onClick={createNewContact}
              disabled={isUpdating}
            >
              Сохранить
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export { AddNewContactPersonForm }
