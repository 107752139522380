import './QuestionScaleInput.css';

export default function QuestionScaleInput({ value, onChange, isComplete }) {
  let values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className={'question-scale-input' + (isComplete ? ' is-complete' : '')}>
      {values.map((valueItem, index) => (
        <div
          key={index}
          className={'scale-item scale-' + valueItem + (valueItem === value ? ' active' : '')}
          onClick={() => onChange(valueItem)}
        >
          {valueItem}
        </div>
      ))}
    </div>
  );
}
