import './MessageAboutEmptyLogin.css';
import {useEffect, useState} from "react";
import {api} from "../../utils/api";

export default function MessageAboutEmptyLogin({}) {
    let [message, setMessage] = useState(null);
    useEffect(()=>{
        api()
            .getMessageAboutEmptyLogin()
            .then(message => {
                setMessage(message);
            });
    }, []);

    return <div className={'message-about-empty-login-container'}>
        <div className={'editable-content'} dangerouslySetInnerHTML={{__html: message}} />
    </div>;
}
