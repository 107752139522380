import './CustomPassword.css';
import {useState} from "react";

export default function CustomPassword({value, label, onChange, onFocus}) {
  let [passwordIsVisible, setPasswordIsVisible] = useState(false);
  return <div className={'password-input'}>
    <input
        type={passwordIsVisible ? 'text' : 'password'}
        className={'custom-input' + (value === '' ? '' : ' with-value')}
        placeholder={label}
        onFocus={onFocus}
        value={value} onChange={e => onChange(e.target.value)} />
    <img src={'/images/' + (passwordIsVisible ? 'remove-' : '') + 'eye.svg'} onClick={()=>{setPasswordIsVisible(!passwordIsVisible);}} alt={'Показать'}/>
  </div>;
}