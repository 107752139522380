import './CustomDropdown.css'
import {Dropdown, Popup} from "semantic-ui-react"
import {useRef} from "react"

export default function CustomDropdown ({
    popupContent,
    label,
    options,
    value,
    onChange,
    iconIfHaveValue,
    search= true,
    selection= true,
}) {
    const dropdownRef = useRef()

    const dropdownElement = (
        <Dropdown
            noResultsMessage={'Не найдено'}
            ref={dropdownRef}
            value={value}
            placeholder={label}
            onChange={(e, data) => {
                onChange(data.value);
            }}
            search={search}
            selection={selection}
            options={options.map((x) => ({ key: x.id, text: x.name, value: x.id }))}
        />
    )

    return (
        <div
            className={'custom-dropdown-container' + (value !== null ? ' with-value' : '') + (value !== null && iconIfHaveValue !== undefined && iconIfHaveValue !== null ? ' with-icon' : '')}
        >
            {popupContent === undefined
                ? dropdownElement
                : <Popup position={'top center'} content={popupContent} hoverable trigger={dropdownElement} />}

            {Boolean(options.length) && (
                <img
                    onClick={(e) => dropdownRef.current.handleClick(e)}
                    className={'dropdown-chevron'}
                    src={window.config.theme === 'Dark' ? '/images/dark/dropdown-chevron.svg' : '/images/dropdown-chevron.svg'}
                    alt="dropdown-arrow"
                />
            )}

            {(value !== null && iconIfHaveValue !== undefined && iconIfHaveValue !== null) && (
                <img
                    onClick={(e) => dropdownRef.current.handleClick(e)}
                    className={'addition-icon'}
                    src={iconIfHaveValue}
                />
            )}
        </div>
    );
}
