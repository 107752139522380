import './NewChatModal.css';
import {Grid, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CustomDropdown from "../../../ServiceRequest/components/CustomDropdown/CustomDropdown";
import CustomTextarea from "../../../../shared/CustomTextarea/CustomTextarea";
import {api} from "../../../../utils/api";
import DealerSelectInput from "../../../../shared/DealerSelectInput/DealerSelectInput";
import {toast} from "react-toastify";

export default function NewChatModal({open, setOpen, userPersonalDealerId, userPersonalDealerName, onCreateNewChat, onChangeDealer}) {
    let [cars, setCars] = useState([]);
    
    let [chatDirectionTypes, setChatDirectionTypes] = useState([]);
    let [chatRequestTypes, setChatRequestTypes] = useState([]);
    let [chatRequestSubTypes, setChatRequestSubTypes] = useState([]);
    
    let [dealerId, setDealerId] = useState(userPersonalDealerId);
    let [dealerName, setDealerName] = useState(userPersonalDealerName);
    let [carId, setCarId] = useState(null);
    
    let [chatDirectionTypeId, setChatDirectionTypeId] = useState(null);
    let [chatRequestTypeId, setChatRequestTypeId] = useState(null);
    let [chatRequestSubTypeId, setChatRequestSubTypeId] = useState(null);
    
    let [initMessage, setInitMessage] = useState('');
    
    let setCarIdAndLoadDirections = (newCarId) => {
        setCarId(newCarId);
        api()
            .getChatAllTypesForNewChatMessage()
            .then((resp)=>{
                setChatDirectionTypes(resp.FeedbackDirectionList.map(x=> ({id: x.DirectionId, name: x.Descr})));
                setChatRequestTypes(resp.FeedbackTypeList.map(x=> ({id: x.TypeId, name: x.Descr, directionId: x.DirectionId})));
                setChatRequestSubTypes(resp.FeedbackReasonList.map(x=> ({id: x.ReasonId, name: x.Descr, requestTypeId: x.TypeId})));
            });
    };

    useEffect(()=>{
        if(open){
            api()
              .getCars()
              .then(carsFromServer=>{
                  setCars(carsFromServer.map(car => ({
                      id: car.CarId,
                      name: car.CarDescr
                  })));
            
                  if(carsFromServer.length === 1)
                      setCarIdAndLoadDirections(carsFromServer[0].CarId)
              });
            setChatDirectionTypeId(null);
            setChatRequestTypeId(null);
            setChatRequestSubTypeId(null);
            setInitMessage('');
        }
    }, [open]);
    useEffect(()=>{
        setDealerId(userPersonalDealerId);
        setDealerName(userPersonalDealerName);
    }, [userPersonalDealerId, userPersonalDealerName]);

    let send = () => {
        api()
            .createNewChatForDealer(carId, chatDirectionTypeId, chatRequestTypeId, chatRequestSubTypeId, initMessage)
            .then(resp=>{
                if(resp.ErrorInfo != '' && resp.ErrorInfo != null){
                    toast.error(resp.ErrorInfo);
                }else{
                    setOpen(false);
                    onCreateNewChat();
                    toast.info(resp.UserInfo);
                }
            });
    };
    return <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      className={'new-chat-modal'}
  >
    <Modal.Content>
        <div className={'title'}>Новое сообщение</div>
        <DealerSelectInput value={dealerId} valueDisplay={dealerName} onChange={onChangeDealer} />
        <CustomDropdown label={'Автомобиль'} value={carId} options={cars}
                      onChange={setCarIdAndLoadDirections}/>
        {dealerId !== null && carId !== null ? <CustomDropdown label={'Направление'} value={chatDirectionTypeId} options={chatDirectionTypes}
                                                               onChange={(typeId) => {
                                                                   setChatDirectionTypeId(typeId);
                                                               }}/> : ''}
        
        {dealerId !== null && carId !== null && chatDirectionTypeId !== null ? <CustomDropdown label={'Тип обращения'} 
                                                                                               value={chatRequestTypeId} 
                                                                                               options={chatRequestTypes.filter(type=> type.directionId === chatDirectionTypeId)} 
                                                                                               onChange={(id)=>{setChatRequestTypeId(id);setChatRequestSubTypeId(null)}}/> : ''}
        
        {dealerId !== null && carId !== null && chatDirectionTypeId !== null && chatRequestTypeId !== null && chatRequestSubTypes.filter(type=> type.requestTypeId === chatRequestTypeId).length > 0 ? <CustomDropdown label={'Подтип'} 
                                                                                               value={chatRequestSubTypeId} 
                                                                                               options={chatRequestSubTypes.filter(type=> type.requestTypeId === chatRequestTypeId)} 
                                                                                               onChange={setChatRequestSubTypeId}/> : ''}
        
        
        {dealerId !== null && carId !== null && chatDirectionTypeId !== null && chatRequestTypeId !== null && 
        (chatRequestSubTypeId !== null || chatRequestSubTypes.filter(type=> type.requestTypeId === chatRequestTypeId).length === 0) ? 
            <CustomTextarea value={initMessage} label={'Введите текст сообщения...'} onChange={setInitMessage}/> : ''}
        <Grid>
            <Grid.Row>
                <Grid.Column width={8} onClick={() => {
                    setOpen(false)
                }}>Отмена</Grid.Column>
                {dealerId !== null && carId !== null && chatDirectionTypeId !== null && chatRequestTypeId !== null &&
                (chatRequestSubTypeId !== null || chatRequestSubTypes.filter(type=> type.requestTypeId === chatRequestTypeId).length === 0)
                && initMessage !== '' ? <Grid.Column width={8} onClick={send}>Отправить</Grid.Column> : 
                    <Grid.Column className={'disabled'} width={8}>Отправить</Grid.Column>}
            </Grid.Row>
        </Grid>
    </Modal.Content>
    </Modal>;
}